import React from "react";

const VolvoCalculatorHeader = () => {
  return (
    <div className="container mx-auto grid grid-cols-2 gap-6 md:grid-cols-12 pt-16 pb-10">
      <div className="col-span-full md:col-start-3 md:col-end-11 text-center">
        <div className="text-3xl md:text-5xl pb-6 font-volvo-novum--light font-light">
          Volvo fuel cost saving calculator
        </div>
        <div className="text-base font-volvo-novum--light font-light">
          Below you can see how much of a fuel cost saving you could make on
          some of our latest plug-in hybrid models.
        </div>
        <div className="text-base font-volvo-novum--light font-light">
          Use the sliders to enter your information.
        </div>
      </div>
    </div>
  );
};

export default VolvoCalculatorHeader;
