export enum VolvoModel {
  XC90,
  XC60,
  XC40,
  S90,
  S60,
  V90,
  V60,
  V40,
  UNDEFINED,
}
