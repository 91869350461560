import React from "react";
import { FuelType } from "../models/FuelType";
import { Volvo } from "../models/Volvo";
import { VolvoModel } from "../models/VolvoModel";

const getCorrespondingVolvoImage = (volvo?: Volvo): string => {
  return require(`../assets/images/volvo-cars/${getVolvoModelTypeAndFuelType(
    true,
    volvo
  )}.jpg`).default;
};

const getCorrespondingVolvoImageAlt = (volvo?: Volvo): string => {
  return `${getVolvoModelTypeAndFuelType(false, volvo)}`;
};

const getVolvoModelTypeAndFuelType = (
  needsHyphen: boolean,
  volvo?: Volvo
): string => {
  return `${getVolvoModel(volvo?.model)}${
    needsHyphen ? "-" : " "
  }${getVolvoFuelType(volvo?.fuelType)}`;
};

const getVolvoModel = (volvoModel?: VolvoModel): string => {
  switch (volvoModel) {
    case VolvoModel.XC40:
      return "XC40";
    case VolvoModel.XC60:
      return "XC60";
    case VolvoModel.XC90:
      return "XC90";
    case VolvoModel.S60:
      return "S60";
    case VolvoModel.S90:
      return "S90";
    case VolvoModel.V40:
      return "V40";
    case VolvoModel.V60:
      return "V60";
    case VolvoModel.V90:
      return "V90";
    default:
      return "UNDEFINED";
  }
};

const getVolvoFuelType = (fuelType?: FuelType): string => {
  switch (fuelType) {
    case FuelType.PETROL:
      return "petrol";
    case FuelType.DIESEL:
      return "diesel";
    case FuelType.PLUGIN_HYBRID:
      return "hybrid";
    case FuelType.ELECTRIC:
      return "electric";
    default:
      return "UNDEFINED";
  }
};

interface VolvoImageProps {
  volvo?: Volvo;
}

const VolvoImage: React.FC<VolvoImageProps> = ({ volvo }) => {
  return (
    <img
      src={getCorrespondingVolvoImage(volvo)}
      alt={getCorrespondingVolvoImageAlt(volvo)}
      className="min-w-max h-48 object-contain"
    />
  );
};

export default VolvoImage;
