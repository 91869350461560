import React from "react";
import { CostSummaryBarItemType } from "../models/CostSummaryBarItem";

interface CostSummaryBarItemProps {
  type: CostSummaryBarItemType;
  price: number;
}

const getTypeAsNumber = (type: CostSummaryBarItemType): number => {
  switch (type) {
    case CostSummaryBarItemType.ITEM_3_YEARS:
      return 3;
    case CostSummaryBarItemType.ITEM_2_YEARS:
      return 2;
    default:
      return 1;
  }
};

const getCorrectPriceBreakdownWithCurrency = (
  type: CostSummaryBarItemType,
  price: number
): string => {
  switch (type) {
    case CostSummaryBarItemType.ITEM_1_MONTH:
      return `£${(price / 12).toFixed(0)}`;
    default:
      return `£${(price * getTypeAsNumber(type)).toLocaleString("en", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}`;
  }
};

const getTitleText = (type: CostSummaryBarItemType): string => {
  const number: number = getTypeAsNumber(type);
  let postFix: string = "";
  switch (type) {
    case CostSummaryBarItemType.ITEM_1_MONTH:
      postFix = "month";
      break;
    default:
      postFix = `year${type === CostSummaryBarItemType.ITEM_1_YEAR ? "" : "s"}`;
  }
  return `${number} ${postFix}`;
};

const getDynamicClassesList = (type: CostSummaryBarItemType): string => {
  const dynamicClassNameList: string =
    type === CostSummaryBarItemType.ITEM_3_YEARS
      ? "font-volvo-novum--medium font-bold"
      : "font-volvo-novum--light font-light";
  return dynamicClassNameList;
};

const CostSummaryBarItem: React.FC<CostSummaryBarItemProps> = ({
  type,
  price,
}) => {
  return (
    <div className="flex-1 min-w-157 flex flex-col items-start pr-7">
      <div className={`text-base lg:text-xl ${getDynamicClassesList(type)}`}>
        {getTitleText(type)}
      </div>
      <div className={`text-3xl lg:text-5xl ${getDynamicClassesList(type)}`}>
        {getCorrectPriceBreakdownWithCurrency(type, price)}
      </div>
    </div>
  );
};

export default CostSummaryBarItem;
