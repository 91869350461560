import React from "react";
import BodyTypeSelectorButton from "./BodyTypeSelectorButton";
import VolvoImage from "./VolvoImage";
import { BodyType } from "../models/BodyType";
import { Volvo } from "../models/Volvo";

interface BodyTypeSelectorSectionProps {
  currentlySelectedVolvo?: Volvo;
  handleBodyTypeButtonPressed: (bodyType: BodyType) => void;
}

const BodyTypeSelectorSection: React.FC<BodyTypeSelectorSectionProps> = ({
  currentlySelectedVolvo,
  handleBodyTypeButtonPressed,
}) => {
  return (
    <div className="col-span-full py-3">
      <div className="text-lg font-volvo-novum--light font-light pb-2">
        What body type is it?
      </div>
      <div className="grid grid-cols-3 gap-x-7 pb-6">
        <BodyTypeSelectorButton
          buttonBodyType={BodyType.SUV}
          currentlySelectedBodyType={currentlySelectedVolvo?.bodyType}
          handleButtonPressed={(selectedBodyType) =>
            handleBodyTypeButtonPressed(selectedBodyType)
          }
        />
        <BodyTypeSelectorButton
          buttonBodyType={BodyType.SALOON}
          currentlySelectedBodyType={currentlySelectedVolvo?.bodyType}
          handleButtonPressed={(selectedBodyType) =>
            handleBodyTypeButtonPressed(selectedBodyType)
          }
        />
        <BodyTypeSelectorButton
          buttonBodyType={BodyType.ESTATE}
          currentlySelectedBodyType={currentlySelectedVolvo?.bodyType}
          handleButtonPressed={(selectedBodyType) =>
            handleBodyTypeButtonPressed(selectedBodyType)
          }
        />
      </div>
      <div className="flex items-center justify-center overflow-x-hidden">
        <VolvoImage volvo={currentlySelectedVolvo} />
      </div>
    </div>
  );
};

export default BodyTypeSelectorSection;
