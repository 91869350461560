import React from "react";
import CostSummaryBar from "./CostSummaryBar";

interface CurrentCarCostSummarySectionProps {
  annualMileage: number;
  currentCarCost: number;
}

const getFormattedAnnualMileage = (mileage: number): string => {
  return mileage.toLocaleString();
};

const CurrentCarCostSummarySection: React.FC<CurrentCarCostSummarySectionProps> = ({
  annualMileage,
  currentCarCost,
}) => {
  return (
    <div className="container mx-auto grid grid-cols-2 lg:grid-cols-12 gap-x-12 pt-10 md:pt-16">
      <div className="col-span-full lg:col-end-7 pb-6">
        <div className="text-3xl font-volvo-novum--light font-light">
          Your current fuel costs
        </div>
        <div className="text-base font-volvo-novum--light font-light">
          The following fuel costs have been calculated based on an annual
          mileage of{" "}
          <span className="font-volvo-novum--medium font-bold">
            {getFormattedAnnualMileage(annualMileage)}
          </span>{" "}
          and the economy values you have provided above.
        </div>
      </div>
      <CostSummaryBar price={currentCarCost} needsLeftPadding={false} />
    </div>
  );
};

export default CurrentCarCostSummarySection;
