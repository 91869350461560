import React from "react";

import tickIcon from "../assets/images/tick-icon.png";

/*-- models --*/
import { BodyType } from "../models/BodyType";

interface BodyTypeSelectorButtonProps {
  buttonBodyType: BodyType;
  currentlySelectedBodyType?: BodyType;
  handleButtonPressed: (buttonBodyType: BodyType) => void;
}

const getButtonTitleText = (buttonBodyType: BodyType): string => {
  switch (buttonBodyType) {
    case BodyType.SUV:
      return "SUV";
    case BodyType.SALOON:
      return "Saloon";
    case BodyType.ESTATE:
      return "Estate";
    case BodyType.HATCHBACK:
      return "Hatchback";
    default:
      return "Unkown";
  }
};

const getDynamicClassesList = (
  buttonBodyType: BodyType,
  currentlySelectedBodyType?: BodyType
): string => {
  if (isButtonSelected(buttonBodyType, currentlySelectedBodyType)) {
    return "bg-primary border-transparent text-white";
  } else {
    return "bg-white border-primary border text-primary";
  }
};

const isButtonSelected = (
  buttonBodyType: BodyType,
  currentlySelectedBodyType?: BodyType
): boolean => {
  return buttonBodyType === currentlySelectedBodyType;
};

const BodyTypeSelectorButton: React.FC<BodyTypeSelectorButtonProps> = ({
  buttonBodyType,
  currentlySelectedBodyType,
  handleButtonPressed,
}) => {
  return (
    <button
      onClick={
        isButtonSelected(buttonBodyType, currentlySelectedBodyType)
          ? undefined
          : () => handleButtonPressed(buttonBodyType)
      }
      className={`flex flex-row items-center justify-center py-4 text-base font-volvo-novum--light font-light ${getDynamicClassesList(
        buttonBodyType,
        currentlySelectedBodyType
      )}`}
    >
      {getButtonTitleText(buttonBodyType)}
      {isButtonSelected(buttonBodyType, currentlySelectedBodyType) ? (
        <img src={tickIcon} alt="tick-icon" className="pl-1" />
      ) : null}
    </button>
  );
};

export default BodyTypeSelectorButton;
