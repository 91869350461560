import { BodyType } from "../models/BodyType";
import { FuelType } from "../models/FuelType";
import { Volvo } from "../models/Volvo";
import { VolvoModel } from "../models/VolvoModel";
import { VolvoStyle } from "../models/VolvoStyle";

export const volvos: Volvo[] = [
  {
    model: VolvoModel.XC40,
    spec: "XC40 T3 manual",
    style: VolvoStyle.MOMENTUM_CORE,
    bodyType: BodyType.SUV,
    fuelType: FuelType.PETROL,
    mpg: 40.4,
    co2: 158,
    electricRange: undefined,
    batterySize: undefined,
    electricityCostPerFullCharge: undefined,
    costPerElectricMileInPence: undefined,
  },
  {
    model: VolvoModel.XC40,
    spec: "XC40 T5 Twin Engine Automatic",
    style: VolvoStyle.INSCRIPTION,
    bodyType: BodyType.SUV,
    fuelType: FuelType.PLUGIN_HYBRID,
    mpg: 53.2,
    co2: 41,
    electricRange: 27,
    batterySize: 8.5,
    electricityCostPerFullCharge: 1.22,
    costPerElectricMileInPence: 0.05,
  },
  {
    model: VolvoModel.XC60,
    spec: "XC60 B5 mild hybrid",
    style: VolvoStyle.MOMENTUM,
    bodyType: BodyType.SUV,
    fuelType: FuelType.PETROL,
    mpg: 37.7,
    co2: 170,
    electricRange: undefined,
    batterySize: undefined,
    electricityCostPerFullCharge: undefined,
    costPerElectricMileInPence: undefined,
  },
  {
    model: VolvoModel.XC60,
    spec: "XC60 B4 mild hybrid",
    style: VolvoStyle.MOMENTUM,
    bodyType: BodyType.SUV,
    fuelType: FuelType.DIESEL,
    mpg: 45.6,
    co2: 162,
    electricRange: undefined,
    batterySize: undefined,
    electricityCostPerFullCharge: undefined,
    costPerElectricMileInPence: undefined,
  },
  {
    model: VolvoModel.XC60,
    spec: "XC60 T8 Twin Engine AWD Automatic",
    style: VolvoStyle.INSCRIPTION,
    bodyType: BodyType.SUV,
    fuelType: FuelType.PLUGIN_HYBRID,
    mpg: 46.2,
    co2: 42,
    electricRange: 32,
    batterySize: 9.2,
    electricityCostPerFullCharge: 1.32,
    costPerElectricMileInPence: 0.04,
  },
  {
    model: VolvoModel.XC90,
    spec: "XC90 B5 AWD mild hybrid",
    style: VolvoStyle.MOMENTUM,
    bodyType: BodyType.SUV,
    fuelType: FuelType.PETROL,
    mpg: 33.2,
    co2: 193,
    electricRange: undefined,
    batterySize: undefined,
    electricityCostPerFullCharge: undefined,
    costPerElectricMileInPence: undefined,
  },
  {
    model: VolvoModel.XC90,
    spec: "XC90 B5 AWD mild hybrid",
    style: VolvoStyle.MOMENTUM,
    bodyType: BodyType.SUV,
    fuelType: FuelType.DIESEL,
    mpg: 41.5,
    co2: 178,
    electricRange: undefined,
    batterySize: undefined,
    electricityCostPerFullCharge: undefined,
    costPerElectricMileInPence: undefined,
  },
  {
    model: VolvoModel.XC90,
    spec: "XC90 T8 Twin Engine AWD Automatic",
    style: VolvoStyle.INSCRIPTION,
    bodyType: BodyType.SUV,
    fuelType: FuelType.PLUGIN_HYBRID,
    mpg: 44.1,
    co2: 52,
    electricRange: 26,
    batterySize: 9.2,
    electricityCostPerFullCharge: 1.32,
    costPerElectricMileInPence: 0.05,
  },
  {
    model: VolvoModel.S60,
    spec: "S60 B5 mild hybrid",
    style: VolvoStyle.R_DESIGN,
    bodyType: BodyType.SALOON,
    fuelType: FuelType.PETROL,
    mpg: 41.5,
    co2: 152,
    electricRange: undefined,
    batterySize: undefined,
    electricityCostPerFullCharge: undefined,
    costPerElectricMileInPence: undefined,
  },
  {
    model: VolvoModel.S60,
    spec: "S60 T8 Twin Engine AWD Automatic",
    style: VolvoStyle.R_DESIGN_PRO,
    bodyType: BodyType.SALOON,
    fuelType: FuelType.PLUGIN_HYBRID,
    mpg: 50.4,
    co2: 42,
    electricRange: 34,
    batterySize: 9.2,
    electricityCostPerFullCharge: 1.32,
    costPerElectricMileInPence: 0.04,
  },
  {
    model: VolvoModel.S90,
    spec: "S90 T8 Twin Engine AWD Automatic",
    style: VolvoStyle.INSCRIPTION_PRO,
    bodyType: BodyType.SALOON,
    fuelType: FuelType.PLUGIN_HYBRID,
    mpg: 51.3,
    co2: 40,
    electricRange: 34,
    batterySize: 9.2,
    electricityCostPerFullCharge: 1.32,
    costPerElectricMileInPence: 0.04,
  },
  {
    model: VolvoModel.V40,
    spec: "V40 T3 Automatic",
    style: VolvoStyle.R_DESIGN_PRO,
    bodyType: BodyType.ESTATE,
    fuelType: FuelType.PETROL,
    mpg: 51.4,
    co2: 129,
    electricRange: undefined,
    batterySize: undefined,
    electricityCostPerFullCharge: undefined,
    costPerElectricMileInPence: undefined,
  },
  {
    model: VolvoModel.V40,
    spec: "V40 D2 Manual",
    style: VolvoStyle.R_DESIGN_PRO,
    bodyType: BodyType.ESTATE,
    fuelType: FuelType.DIESEL,
    mpg: 78.5,
    co2: 94,
    electricRange: undefined,
    batterySize: undefined,
    electricityCostPerFullCharge: undefined,
    costPerElectricMileInPence: undefined,
  },
  {
    model: VolvoModel.V60,
    spec: "V60 B4 mild hybrid",
    style: VolvoStyle.MOMENTUM,
    bodyType: BodyType.ESTATE,
    fuelType: FuelType.PETROL,
    mpg: 41.5,
    co2: 152,
    electricRange: undefined,
    batterySize: undefined,
    electricityCostPerFullCharge: undefined,
    costPerElectricMileInPence: undefined,
  },
  {
    model: VolvoModel.V60,
    spec: "V60 B4 mild hybrid",
    style: VolvoStyle.MOMENTUM,
    bodyType: BodyType.ESTATE,
    fuelType: FuelType.DIESEL,
    mpg: 50.4,
    co2: 146,
    electricRange: undefined,
    batterySize: undefined,
    electricityCostPerFullCharge: undefined,
    costPerElectricMileInPence: undefined,
  },
  {
    model: VolvoModel.V60,
    spec: "V60 T8 Twin Engine AWD Automatic",
    style: VolvoStyle.R_DESIGN_PRO,
    bodyType: BodyType.ESTATE,
    fuelType: FuelType.PLUGIN_HYBRID,
    mpg: 48.6,
    co2: 42,
    electricRange: 34,
    batterySize: 9.2,
    electricityCostPerFullCharge: 1.32,
    costPerElectricMileInPence: 0.04,
  },
  {
    model: VolvoModel.V90,
    spec: "V90 B4 mild hybrid",
    style: VolvoStyle.MOMENTUM,
    bodyType: BodyType.ESTATE,
    fuelType: FuelType.PETROL,
    mpg: 40.9,
    co2: 157,
    electricRange: undefined,
    batterySize: undefined,
    electricityCostPerFullCharge: undefined,
    costPerElectricMileInPence: undefined,
  },
  {
    model: VolvoModel.V90,
    spec: "V90 B4 mild hybrid",
    style: VolvoStyle.MOMENTUM,
    bodyType: BodyType.ESTATE,
    fuelType: FuelType.DIESEL,
    mpg: 50.4,
    co2: 148,
    electricRange: undefined,
    batterySize: undefined,
    electricityCostPerFullCharge: undefined,
    costPerElectricMileInPence: undefined,
  },
  {
    model: VolvoModel.V90,
    spec: "V90 T8 Twin Engine AWD Automatic",
    style: VolvoStyle.R_DESIGN_PRO,
    bodyType: BodyType.ESTATE,
    fuelType: FuelType.PLUGIN_HYBRID,
    mpg: 49.5,
    co2: 43,
    electricRange: 33,
    batterySize: 9.2,
    electricityCostPerFullCharge: 1.32,
    costPerElectricMileInPence: 0.04,
  },
];
