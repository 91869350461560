import React from "react";

/*-- components --*/
import CostCalculatorSliderSection from "./CostCalculatorSliderSection";

/*-- models --*/
import { CostCalculatorSliderSectionType } from "../models/CostCalculatorSliderSectionType";
import { FuelType } from "../models/FuelType";
import { Volvo } from "../models/Volvo";

interface CostCalculatorSectionProps {
  isCurrentVehicleAVolvo: boolean;
  currentVolvo: Volvo;
  currentNonVolvoVehicleFuelType: FuelType;
  driverWeekdayMileage: number;
  driverWeekendMileage: number;
  currentVehicleFuelEconomy: number;
  currentFuelPricePerLitre: number;
  onSliderValueChange: (
    sliderSectionType: CostCalculatorSliderSectionType,
    updatedSliderValue: number
  ) => void;
}

const getFuelType = (
  isCurrentVehicleAVolvo: boolean,
  currentVolvo: Volvo,
  currentNonVolvoVehicleFuelType: FuelType
): FuelType => {
  return isCurrentVehicleAVolvo
    ? currentVolvo.fuelType
    : currentNonVolvoVehicleFuelType;
};

// const getFuelEconomy = (
//   isCurrentVehicleAVolvo: boolean,
//   currentVolvo: Volvo,
//   currentNonVolvoVehicleFuelEconomy: number
// ): number => {
//   return isCurrentVehicleAVolvo
//     ? currentVolvo.mpg
//     : currentNonVolvoVehicleFuelEconomy;
// };

const CostCalculatorSection: React.FC<CostCalculatorSectionProps> = ({
  isCurrentVehicleAVolvo,
  currentVolvo,
  currentNonVolvoVehicleFuelType,
  driverWeekdayMileage,
  driverWeekendMileage,
  currentVehicleFuelEconomy,
  currentFuelPricePerLitre,
  onSliderValueChange,
}) => {
  return (
    <div className="bg-structure/100">
      <div className="container md:mx-auto grid grid-cols-2 gap-x-7 gap-y-10 pt-10 pb-24">
        <CostCalculatorSliderSection
          sectionType={CostCalculatorSliderSectionType.WEEKDAY_MILEAGE}
          isCurrentCarAVolvo={isCurrentVehicleAVolvo}
          currentVolvo={currentVolvo}
          currentNonVolvoVehicleFuelType={getFuelType(
            isCurrentVehicleAVolvo,
            currentVolvo,
            currentNonVolvoVehicleFuelType
          )}
          value={driverWeekdayMileage}
          onSliderValueChange={(updatedSliderValue: number) =>
            onSliderValueChange(
              CostCalculatorSliderSectionType.WEEKDAY_MILEAGE,
              updatedSliderValue
            )
          }
        />
        <CostCalculatorSliderSection
          sectionType={CostCalculatorSliderSectionType.WEEKEND_MILEAGE}
          isCurrentCarAVolvo={isCurrentVehicleAVolvo}
          currentVolvo={currentVolvo}
          currentNonVolvoVehicleFuelType={getFuelType(
            isCurrentVehicleAVolvo,
            currentVolvo,
            currentNonVolvoVehicleFuelType
          )}
          value={driverWeekendMileage}
          onSliderValueChange={(updatedSliderValue: number) =>
            onSliderValueChange(
              CostCalculatorSliderSectionType.WEEKEND_MILEAGE,
              updatedSliderValue
            )
          }
        />
        <CostCalculatorSliderSection
          sectionType={CostCalculatorSliderSectionType.FUEL_ECONOMY}
          isCurrentCarAVolvo={isCurrentVehicleAVolvo}
          currentVolvo={currentVolvo}
          currentNonVolvoVehicleFuelType={getFuelType(
            isCurrentVehicleAVolvo,
            currentVolvo,
            currentNonVolvoVehicleFuelType
          )}
          value={currentVehicleFuelEconomy}
          onSliderValueChange={(updatedSliderValue: number) =>
            onSliderValueChange(
              CostCalculatorSliderSectionType.FUEL_ECONOMY,
              updatedSliderValue
            )
          }
        />
        <CostCalculatorSliderSection
          sectionType={CostCalculatorSliderSectionType.PETROL_COST}
          isCurrentCarAVolvo={isCurrentVehicleAVolvo}
          currentVolvo={currentVolvo}
          currentNonVolvoVehicleFuelType={getFuelType(
            isCurrentVehicleAVolvo,
            currentVolvo,
            currentNonVolvoVehicleFuelType
          )}
          value={currentFuelPricePerLitre}
          onSliderValueChange={(updatedSliderValue: number) =>
            onSliderValueChange(
              CostCalculatorSliderSectionType.PETROL_COST,
              updatedSliderValue
            )
          }
        />
      </div>
    </div>
  );
};

export default CostCalculatorSection;
