import React from "react";
import ReactTooltip from "react-tooltip";
import InfoIcon from "./InfoBanners/InfoIcon";
import { CostCalculatorSliderSectionType } from "../models/CostCalculatorSliderSectionType";

interface TooltipProps {
  type: CostCalculatorSliderSectionType;
}

const getTooltipText = (type: CostCalculatorSliderSectionType): string => {
  switch (type) {
    case CostCalculatorSliderSectionType.WEEKDAY_MILEAGE:
      return "Please enter the miles <br/>you’d cover on a typical weekday,<br/>including any miles you <br/>normally do commuting to work.";
    case CostCalculatorSliderSectionType.WEEKEND_MILEAGE:
      return "Please enter the miles <br/>you’d cover on a typical weekend.";
    case CostCalculatorSliderSectionType.FUEL_ECONOMY:
      return "";
    case CostCalculatorSliderSectionType.PETROL_COST:
      return "";
    case CostCalculatorSliderSectionType.ELECTRICITY_COST:
      return "You can find your <br/>electricity costs on your <br/>latest energy bill.";
  }
};

const Tooltip: React.FC<TooltipProps> = ({ type }) => {
  return (
    <div>
      <button data-tip={getTooltipText(type)} data-for={type.toString()}>
        <InfoIcon />
      </button>
      <ReactTooltip
        id={type.toString()}
        place={"top"}
        type="light"
        effect="solid"
        multiline={true}
        backgroundColor="#FAFAFC"
        border={true}
        borderColor="#BDBDBD"
      />
    </div>
  );
};
export default Tooltip;
