import React, { useEffect, useState } from "react";

/*-- components --*/
import VolvoCalculatorHeader from "./components/VolvoCalculatorHeader";
import CurrentVolvoSelectorSection from "./components/CurrentVolvoSelectorSection";
import CostCalculatorSection from "./components/CostCalculatorSection";
import CurrentCarCostSummarySection from "./components/CurrentCarCostSummarySection";
import VolvoCarComparisonSection from "./components/VolvoCarComparisonSection";
import ElectricityCostCalculatorSection from "./components/ElectricityCostCalculatorSection";
import FuelSavingComparisonResultsSection from "./components/FuelSavingComparisonResultsSection";

/*-- models --*/
import { CostCalculatorSliderSectionType } from "./models/CostCalculatorSliderSectionType";
import { FuelType } from "./models/FuelType";
import { Volvo } from "./models/Volvo";

/*-- data --*/
import { volvos } from "./data/volvo-models";
import { BodyType } from "./models/BodyType";
import { VolvoModel } from "./models/VolvoModel";
import { FormSelectParentCardType } from "./models/FormSelectParentCardType";
import { FormSelectSectionType } from "./models/FormSelectSectionType";
import { FuelSavingComparisonResult } from "./models/FuelSavingComparisonResult";

const NUMBER_OF_WEEKS_IN_A_YEAR: number = 52;
const NUMBER_OF_WEEKDAY_DAYS_PER_WEEK: number = 5;
const NUMBER_OF_WEEKEND_DAYS_PER_WEEK: number = 2;
export const CURRENT_PETROL_PRICE: number = 1.44;
export const CURRENT_DIESEL_PRICE: number = 1.23;

const getAnnualElectricMileage = (
  weekdayMiles: number,
  weekendMiles: number,
  currentVehicleElectricRange: number = 0,
  canChargeAtWork: boolean = false
): number => {
  const yearlyWeekdayElectricMileage: number = getYearlyWeekdayElectricMileage(
    weekdayMiles,
    currentVehicleElectricRange,
    canChargeAtWork
  );
  const yearlyWeekendElectricMileage: number = getYearlyWeekendElectricMileage(
    weekendMiles,
    currentVehicleElectricRange
  );
  return yearlyWeekdayElectricMileage + yearlyWeekendElectricMileage;
};

const getAnnualPetrolMileage = (
  weekdayMiles: number,
  weekendMiles: number,
  currentVehicleElectricRange: number | undefined,
  canChargeAtWork: boolean
): number => {
  const yearlyWeekdayPetrolMileage: number = getYearlyWeekdayPetrolMileage(
    weekdayMiles,
    currentVehicleElectricRange,
    canChargeAtWork
  );
  const yearlyWeekendPetrolMileage: number = getYearlyWeekendPetrolMileage(
    weekendMiles,
    currentVehicleElectricRange
  );
  return yearlyWeekdayPetrolMileage + yearlyWeekendPetrolMileage;
};

const getAnnualMileage = (
  weekdayMiles: number,
  weekendMiles: number
): number => {
  const weekdayMileage: number = getYearlyWeekdayMileage(weekdayMiles);
  const weekendMileage: number = getYearlyWeekendMileage(weekendMiles);
  return weekdayMileage + weekendMileage;
};

const getYearlyWeekdayPetrolMileage = (
  weekdayMiles: number,
  currentVehicleElectricRange: number = 0,
  canChargeAtWork: boolean = false
): number => {
  const yearWeekdayMiles: number = getYearlyWeekdayMileage(weekdayMiles);
  const yearlyWeekdayElectricMileage: number = getYearlyWeekdayElectricMileage(
    weekdayMiles,
    currentVehicleElectricRange,
    canChargeAtWork
  );
  const yearlyWeekdayPetrolMileage: number =
    yearWeekdayMiles - yearlyWeekdayElectricMileage;
  return yearlyWeekdayPetrolMileage;
};

const getYearlyWeekdayElectricMileage = (
  weekdayMileage: number,
  currentVehicleElectricRange: number,
  canChargeAtWork: boolean
): number => {
  const canChargeAtWorkMultiplier: number = canChargeAtWork ? 2 : 1;
  const availableElectricMilesPerDay: number =
    currentVehicleElectricRange * canChargeAtWorkMultiplier;
  const availableElectricMilesPerWeek: number =
    availableElectricMilesPerDay * NUMBER_OF_WEEKDAY_DAYS_PER_WEEK;
  const availableElectricMilesPerYear: number =
    availableElectricMilesPerWeek * NUMBER_OF_WEEKS_IN_A_YEAR;
  const weekdayMilesDrivenPerYear: number =
    getYearlyWeekdayMileage(weekdayMileage);
  const yearlyWeekdayElectricMileage: number =
    availableElectricMilesPerYear <= weekdayMilesDrivenPerYear
      ? availableElectricMilesPerYear
      : weekdayMilesDrivenPerYear;
  return yearlyWeekdayElectricMileage;
};

const getYearlyWeekdayMileage = (weekdayMiles: number): number => {
  const weeklyWeekdayMiles: number =
    weekdayMiles * NUMBER_OF_WEEKDAY_DAYS_PER_WEEK;

  /*-- a year is 52 weeks + 1 day,
       hence why we add an extra 
       instance of weekday miles 
       onto the calculation
  --*/
  const yearlyWeekdayMiles: number =
    weeklyWeekdayMiles * NUMBER_OF_WEEKS_IN_A_YEAR + 1 * weekdayMiles;
  return yearlyWeekdayMiles;
};

const getYearlyWeekendPetrolMileage = (
  weekendMileage: number,
  currentVehicleElectricRange: number = 0
): number => {
  const yearlyWeekendMileage: number = getYearlyWeekendMileage(weekendMileage);
  const yearlyWeekendElectricMileage: number = getYearlyWeekendElectricMileage(
    weekendMileage,
    currentVehicleElectricRange
  );
  const yearlyWeekendPetrolMileage: number =
    yearlyWeekendMileage - yearlyWeekendElectricMileage;
  return yearlyWeekendPetrolMileage;
};

const getYearlyWeekendElectricMileage = (
  weekendMileage: number,
  currentVehicleElectricRange: number
): number => {
  const availableElectricMilesPerWeek: number =
    currentVehicleElectricRange * NUMBER_OF_WEEKEND_DAYS_PER_WEEK;
  const availableElectricMilesPerYear: number =
    availableElectricMilesPerWeek * NUMBER_OF_WEEKS_IN_A_YEAR;
  const weekendMilesDrivenPerYear: number =
    getYearlyWeekendMileage(weekendMileage);
  const yearlyWeekendElectricMileage: number =
    availableElectricMilesPerYear <= weekendMilesDrivenPerYear
      ? availableElectricMilesPerYear
      : weekendMilesDrivenPerYear;
  return yearlyWeekendElectricMileage;
};

const getYearlyWeekendMileage = (weekendMiles: number): number => {
  const weeklyWeekendMiles: number =
    weekendMiles * NUMBER_OF_WEEKEND_DAYS_PER_WEEK;
  const yearlyWeekendMiles: number =
    weeklyWeekendMiles * NUMBER_OF_WEEKS_IN_A_YEAR;
  return yearlyWeekendMiles;
};

const getCurrentCarCost = (
  weekdayDailyMileage: number,
  weekendDailyMileage: number,
  currentCarMilesPerGallon: number,
  petrolPricePerLitre: number
): number => {
  const annualMileage: number = getAnnualMileage(
    weekdayDailyMileage,
    weekendDailyMileage
  );
  const fuelEconomyInMilesPerLitre: number = getFuelEconomyInMilesPerLitre(
    currentCarMilesPerGallon
  );
  const numberOfLitresUsedAnnually: number =
    annualMileage / fuelEconomyInMilesPerLitre;
  const currentCarCost: number =
    numberOfLitresUsedAnnually * petrolPricePerLitre;
  return currentCarCost;
};

const getCostOfFullBatteryCharge = (
  electricityCost: number,
  batterySize?: number
): number => {
  return batterySize !== undefined ? batterySize * electricityCost : 0;
};

const getElectricCostPerMile = (
  fullBatteryChargeCost: number,
  carElectricRange?: number
): number => {
  return carElectricRange !== undefined
    ? fullBatteryChargeCost / carElectricRange
    : 0;
};

const getComparableVolvoCarCost = (
  comparableVolvo: Volvo,
  weekdayMileage: number,
  weekendMileage: number,
  electricityCost: number,
  canChargeAtWork: boolean,
  petrolPricePerLitre: number
): number => {
  /*-- electric --*/
  const annualElectricMileage: number = getAnnualElectricMileage(
    weekdayMileage,
    weekendMileage,
    comparableVolvo.electricRange,
    canChargeAtWork
  );
  const costOfFullBatteryCharge: number = getCostOfFullBatteryCharge(
    electricityCost,
    comparableVolvo.batterySize
  );
  const electricCostPerMile: number = getElectricCostPerMile(
    costOfFullBatteryCharge,
    comparableVolvo.electricRange
  );
  const annualElectricCost: number =
    electricCostPerMile * annualElectricMileage;

  /*-- fuel --*/
  const annualPetrolMileage: number = getAnnualPetrolMileage(
    weekdayMileage,
    weekendMileage,
    comparableVolvo.electricRange,
    canChargeAtWork
  );
  const fuelEconomyInMilesPerLitre: number = getFuelEconomyInMilesPerLitre(
    comparableVolvo.mpg
  );
  const numberOfLitresUsedAnnually: number =
    annualPetrolMileage / fuelEconomyInMilesPerLitre;
  const annualFuelCost: number =
    numberOfLitresUsedAnnually * petrolPricePerLitre;

  const comparableVolvoCost: number = annualElectricCost + annualFuelCost;
  return comparableVolvoCost;
};

const getFuelSavingComparisonResults = (
  weekdayDailyMileage: number,
  weekendDailyMileage: number,
  currentCarMilesPerGallon: number,
  petrolPricePerLitre: number,
  canChargeAtWork: boolean,
  electricityCost: number,
  firstComparableVolvo: Volvo,
  secondComparableVolvo?: Volvo
): FuelSavingComparisonResult[] => {
  const currentCarCost: number = getCurrentCarCost(
    weekdayDailyMileage,
    weekendDailyMileage,
    currentCarMilesPerGallon,
    petrolPricePerLitre
  );

  let fuelSavingComparisonResults: FuelSavingComparisonResult[] = [];

  /*-- do the work here --*/
  const firstComparableVolvoCost: number = getComparableVolvoCarCost(
    firstComparableVolvo,
    weekdayDailyMileage,
    weekendDailyMileage,
    electricityCost,
    canChargeAtWork,
    petrolPricePerLitre
  );
  const firstComparableVolvoComparisonResult: FuelSavingComparisonResult = {
    comparableCar: firstComparableVolvo,
    saving: currentCarCost - firstComparableVolvoCost,
  };

  fuelSavingComparisonResults.push(firstComparableVolvoComparisonResult);

  if (secondComparableVolvo !== undefined) {
    const secondComparableVolvoCost: number = getComparableVolvoCarCost(
      secondComparableVolvo,
      weekdayDailyMileage,
      weekendDailyMileage,
      electricityCost,
      secondComparableVolvo.fuelType === FuelType.PLUGIN_HYBRID &&
        canChargeAtWork,
      petrolPricePerLitre
    );
    const secondComparableVolvoComparisonResult: FuelSavingComparisonResult = {
      comparableCar: secondComparableVolvo,
      saving: currentCarCost - secondComparableVolvoCost,
    };

    fuelSavingComparisonResults.push(secondComparableVolvoComparisonResult);
  }

  return fuelSavingComparisonResults;
};

const getFuelEconomyInMilesPerLitre = (milesPerGallon: number): number => {
  return milesPerGallon * 0.22;
};

const App = () => {
  const getInitialVolvo = (): Volvo => {
    return volvos[0];
  };

  const getInitialFirstComparableVolvo = (): Volvo => {
    const initialFirstComparableVolvo: Volvo = volvos.filter(
      (volvo) => volvo.fuelType === FuelType.PLUGIN_HYBRID
    )[0];
    return initialFirstComparableVolvo;
  };

  const getInitialFuelEconomy = (): number => {
    return volvos[0].mpg;
  };

  const updateFuelEconomy = (): void => {
    if (isCurrentVehicleAVolvo) {
      setCurrentVehicleFuelEconomy(currentVolvo.mpg);
    } else {
      if (currentNonVolvoVehicleFuelType === FuelType.PETROL) {
        setCurrentVehicleFuelEconomy(36.0);
      } else {
        setCurrentVehicleFuelEconomy(42.0);
      }
    }
  };

  const updateCurrentVolvo = (
    bodyType?: BodyType,
    model?: VolvoModel,
    spec?: string,
    fuelType?: FuelType
  ): void => {
    /*-- this needs better logic --*/
    if (bodyType !== undefined) {
      const volvo: Volvo = volvos.filter((volvo) => {
        return volvo.bodyType === bodyType;
      })[0];
      setCurrentVolvo(volvo);
    } else if (model !== undefined) {
      const volvo: Volvo = volvos.filter((volvo) => {
        return (
          volvo.bodyType === currentVolvo.bodyType && volvo.model === model
        );
      })[0];
      setCurrentVolvo(volvo);
    } else if (spec !== undefined) {
      const volvo: Volvo = volvos.filter(
        (volvo) =>
          volvo.bodyType === currentVolvo.bodyType &&
          volvo.model === currentVolvo.model &&
          volvo.spec === spec
      )[0];
      setCurrentVolvo(volvo);
    } else if (fuelType !== undefined) {
      const volvo: Volvo = volvos.filter(
        (volvo) =>
          volvo.bodyType === currentVolvo.bodyType &&
          volvo.model === currentVolvo.model &&
          volvo.fuelType === fuelType
      )[0];
      setCurrentVolvo(volvo);
    }
  };

  const addSecondComparableVolvo = (): void => {
    const secondComparableVolvo: Volvo = volvos.filter((volvo) => {
      return (
        volvo !== currentVolvo &&
        volvo !== firstComparableVolvo &&
        volvo.fuelType === FuelType.PLUGIN_HYBRID
      );
    })[0];
    setSecondComparableVolvo(secondComparableVolvo);
  };

  const updateComparableVolvos = (
    parentCardType: FormSelectParentCardType,
    bodyType: BodyType
  ): void => {
    switch (parentCardType) {
      case FormSelectParentCardType.COMPARE_PLUGIN_HYBRID:
        /*-- first comparison card --*/
        const updatedFirstComparableVolvo: Volvo = volvos.filter(
          (volvo) =>
            volvo.bodyType === bodyType &&
            volvo.fuelType === FuelType.PLUGIN_HYBRID
        )[0];
        setFirstComparableVolvo(updatedFirstComparableVolvo);
        break;
      case FormSelectParentCardType.COMPARE_ANOTHER_VOLVO:
        /*-- second comparison card --*/
        const updatedSecondComparableVolvo: Volvo = volvos.filter(
          (volvo) =>
            volvo !== currentVolvo &&
            volvo !== firstComparableVolvo &&
            volvo.bodyType === bodyType
        )[0];
        setSecondComparableVolvo(updatedSecondComparableVolvo);
        break;
      default:
        /*-- we should never be here --*/ break;
    }
  };

  const updateFirstComparableVolvo = (spec: string): void => {
    const newFirstComparableVolvo: Volvo = volvos.filter(
      (volvo) =>
        volvo.fuelType === FuelType.PLUGIN_HYBRID && volvo.spec === spec
    )[0];
    setFirstComparableVolvo(newFirstComparableVolvo);
  };

  const updateSecondComparableVolvo = (
    fuelType: FuelType | undefined,
    spec: string | undefined
  ): void => {
    if (fuelType !== undefined) {
      const newSecondComparableVolvo: Volvo = volvos.filter(
        (volvo) =>
          volvo !== currentVolvo &&
          volvo !== firstComparableVolvo &&
          volvo.bodyType === secondComparableVolvo?.bodyType &&
          volvo.fuelType === fuelType
      )[0];
      setSecondComparableVolvo(newSecondComparableVolvo);
    }

    if (spec !== undefined) {
      const newSecondComparableVolvo: Volvo = volvos.filter(
        (volvo) =>
          volvo !== currentVolvo &&
          volvo !== firstComparableVolvo &&
          volvo.bodyType === secondComparableVolvo?.bodyType &&
          volvo.spec === spec
      )[0];
      setSecondComparableVolvo(newSecondComparableVolvo);
    }
  };

  const [isCurrentVehicleAVolvo, setIsCurrentVehicleAVolvo] =
    useState<boolean>(true);
  const [currentVolvo, setCurrentVolvo] = useState<Volvo>(getInitialVolvo);
  const [currentNonVolvoVehicleFuelType, setCurrentNonVolvoVehicleFuelType] =
    useState<FuelType>(FuelType.PETROL);
  const [driverWeekdayMileage, setDriverWeekdayMileage] = useState<number>(20);
  const [driverWeekendMileage, setDriverWeekendMileage] = useState<number>(20);
  const [currentVehicleFuelEconomy, setCurrentVehicleFuelEconomy] =
    useState<number>(getInitialFuelEconomy);
  const [currentFuelPricePerLitre, setCurrentFuelPricePerLitre] =
    useState<number>(CURRENT_PETROL_PRICE);
  const [firstComparableVolvo, setFirstComparableVolvo] = useState<Volvo>(
    getInitialFirstComparableVolvo()
  );
  const [secondComparableVolvo, setSecondComparableVolvo] = useState<Volvo>();
  const [electricityCost, setElectricityCost] = useState<number>(0.14);
  const [canChargeAtWork, setCanChargeAtWork] = useState<boolean>(false);

  useEffect(updateFuelEconomy, [
    isCurrentVehicleAVolvo,
    currentVolvo,
    currentNonVolvoVehicleFuelType,
  ]);

  const updateCalculationProperties = (
    sliderSectionType: CostCalculatorSliderSectionType,
    updatedValue: number
  ): void => {
    switch (sliderSectionType) {
      case CostCalculatorSliderSectionType.WEEKDAY_MILEAGE:
        setDriverWeekdayMileage(updatedValue);
        break;
      case CostCalculatorSliderSectionType.WEEKEND_MILEAGE:
        setDriverWeekendMileage(updatedValue);
        break;
      case CostCalculatorSliderSectionType.FUEL_ECONOMY:
        setCurrentVehicleFuelEconomy(updatedValue);
        break;
      case CostCalculatorSliderSectionType.PETROL_COST:
        setCurrentFuelPricePerLitre(updatedValue);
        break;
    }
  };

  const handleCorrectSelectUpdate = (
    sectionType: FormSelectSectionType,
    parentCardType: FormSelectParentCardType,
    value: VolvoModel | string | FuelType
  ): void => {
    switch (parentCardType) {
      case FormSelectParentCardType.SELECT_CURRENT_VOLVO:
        switch (sectionType) {
          case FormSelectSectionType.CHOOSE_MODEL:
            const model: VolvoModel = value as VolvoModel;
            updateCurrentVolvo(undefined, model, undefined, undefined);
            break;
          case FormSelectSectionType.CHOOSE_CAR:
            const spec: string = value as string;
            updateCurrentVolvo(undefined, undefined, spec, undefined);
            break;
          case FormSelectSectionType.CHOOSE_FUEL_TYPE:
            const fuelType: FuelType = value as FuelType;
            updateCurrentVolvo(undefined, undefined, undefined, fuelType);
            setCurrentFuelPricePerLitre(
              fuelType === FuelType.PETROL
                ? CURRENT_PETROL_PRICE
                : CURRENT_DIESEL_PRICE
            );
            break;
        }
        break;

      case FormSelectParentCardType.SELECT_NON_VOLVO_FUEL_TYPE:
        if (sectionType === FormSelectSectionType.CHOOSE_FUEL_TYPE) {
          const fuelType: FuelType = value as FuelType;
          setCurrentNonVolvoVehicleFuelType(fuelType);
          setCurrentFuelPricePerLitre(
            fuelType === FuelType.PETROL
              ? CURRENT_PETROL_PRICE
              : CURRENT_DIESEL_PRICE
          );
        }
        break;

      case FormSelectParentCardType.COMPARE_PLUGIN_HYBRID:
        const spec: string = value as string;
        updateFirstComparableVolvo(spec);
        break;

      case FormSelectParentCardType.COMPARE_ANOTHER_VOLVO:
        switch (sectionType) {
          case FormSelectSectionType.CHOOSE_FUEL_TYPE:
            const fuelType: FuelType = value as FuelType;
            updateSecondComparableVolvo(fuelType, undefined);
            break;
          case FormSelectSectionType.CHOOSE_CAR:
            const spec: string = value as string;
            updateSecondComparableVolvo(undefined, spec);
            break;
          default:
            /*-- we shouldn't be here --*/ break;
        }
    }
  };

  return (
    <div className="App">
      <VolvoCalculatorHeader />
      <CurrentVolvoSelectorSection
        isToggledOn={isCurrentVehicleAVolvo}
        handleToggleDidChange={() => {
          setIsCurrentVehicleAVolvo(!isCurrentVehicleAVolvo);
        }}
        handleBodyTypeDidChange={(bodyType) => {
          updateCurrentVolvo(bodyType);
        }}
        currentCar={currentVolvo}
        currentNonVolvoVehicleFuelType={currentNonVolvoVehicleFuelType}
        handleFormSelectValueDidChange={(
          sectionType,
          parentCardType,
          value
        ) => {
          handleCorrectSelectUpdate(sectionType, parentCardType, value);
        }}
      />
      <CostCalculatorSection
        isCurrentVehicleAVolvo={isCurrentVehicleAVolvo}
        currentVolvo={currentVolvo}
        currentNonVolvoVehicleFuelType={currentNonVolvoVehicleFuelType}
        driverWeekdayMileage={driverWeekdayMileage}
        driverWeekendMileage={driverWeekendMileage}
        currentVehicleFuelEconomy={currentVehicleFuelEconomy}
        currentFuelPricePerLitre={currentFuelPricePerLitre}
        onSliderValueChange={(sliderSectionType, updatedSliderValue) =>
          updateCalculationProperties(sliderSectionType, updatedSliderValue)
        }
      />
      <CurrentCarCostSummarySection
        annualMileage={getAnnualMileage(
          driverWeekdayMileage,
          driverWeekendMileage
        )}
        currentCarCost={getCurrentCarCost(
          driverWeekdayMileage,
          driverWeekendMileage,
          currentVehicleFuelEconomy,
          currentFuelPricePerLitre
        )}
      />
      <div style={{ paddingBottom: 100 }} />
      {/* <VolvoCarComparisonSection
        currentNonVolvoVehicleFuelType={currentNonVolvoVehicleFuelType}
        firstComparableVolvo={firstComparableVolvo}
        secondComparableVolvo={secondComparableVolvo}
        handleAddAnotherVolvoCarComparisonCardButtonPressed={() =>
          addSecondComparableVolvo()
        }
        handleBodyTypeButtonPressed={(parentCardType, bodyType) =>
          updateComparableVolvos(parentCardType, bodyType)
        }
        handleFormSelectValueDidChange={(sectionType, parentCardType, value) =>
          handleCorrectSelectUpdate(sectionType, parentCardType, value)
        }
      /> */}
      {/* <ElectricityCostCalculatorSection
        isCurrentCarAVolvo={isCurrentVehicleAVolvo}
        currentVolvo={currentVolvo}
        currentNonVolvoVehicleFuelType={currentNonVolvoVehicleFuelType}
        electricityCosts={electricityCost}
        onSliderValueChange={(updatedSliderValue) =>
          setElectricityCost(updatedSliderValue)
        }
      /> */}
      {/* <FuelSavingComparisonResultsSection
        isToggledOn={canChargeAtWork}
        handleToggleDidChange={() => setCanChargeAtWork(!canChargeAtWork)}
        annualMileage={getAnnualMileage(
          driverWeekdayMileage,
          driverWeekendMileage
        )}
        fuelSavingComparisonResults={getFuelSavingComparisonResults(
          driverWeekdayMileage,
          driverWeekendMileage,
          currentVehicleFuelEconomy,
          currentFuelPricePerLitre,
          canChargeAtWork,
          electricityCost,
          firstComparableVolvo,
          secondComparableVolvo
        )}
      /> */}
    </div>
  );
};

export default App;
