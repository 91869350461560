import React from "react";
import VolvoCarSwitchSection from "./VolvoCarSwitchSection";
import BodyTypeSelectorSection from "./BodyTypeSelectorSection";
import FormSelectSection from "./FormSelectSection";
import InfoBanner from "./InfoBanners/InfoBanner";
import { Volvo } from "../models/Volvo";
import { BodyType } from "../models/BodyType";
import { InfoBannerType } from "../models/InfoBannerType";
import { FormSelectParentCardType } from "../models/FormSelectParentCardType";
import { FormSelectSectionType } from "../models/FormSelectSectionType";
import { VolvoModel } from "../models/VolvoModel";
import { FuelType } from "../models/FuelType";

interface CurrentVolvoSelectorSectionProps {
  isToggledOn: boolean;
  handleToggleDidChange: () => void;
  handleBodyTypeDidChange: (bodyType: BodyType) => void;
  currentCar: Volvo;
  currentNonVolvoVehicleFuelType: FuelType;
  handleFormSelectValueDidChange: (
    sectionType: FormSelectSectionType,
    parentCardType: FormSelectParentCardType,
    changedValue: VolvoModel | string | FuelType
  ) => void;
}

const CurrentVolvoSelectorSection: React.FC<CurrentVolvoSelectorSectionProps> = ({
  isToggledOn,
  handleToggleDidChange,
  handleBodyTypeDidChange,
  currentCar,
  currentNonVolvoVehicleFuelType,
  handleFormSelectValueDidChange,
}) => {
  return (
    <div className="md:container md:mx-auto grid grid-cols-2 lg:grid-cols-12 gap-x-12">
      <div className="col-span-full border shadow md:shadow-lg py-7 px-4 md:px-7 md:mb-16">
        <VolvoCarSwitchSection
          isToggledOn={isToggledOn}
          handleToggleDidChange={handleToggleDidChange}
        />
        {isToggledOn ? (
          <div className="col-span-full">
            <BodyTypeSelectorSection
              currentlySelectedVolvo={currentCar}
              handleBodyTypeButtonPressed={(bodyType) =>
                handleBodyTypeDidChange(bodyType)
              }
            />
            <div className="grid grid-cols-2 md:grid-cols-12 gap-x-7">
              <div className="col-span-full md:col-span-6 pb-5">
                <FormSelectSection
                  parentCardType={FormSelectParentCardType.SELECT_CURRENT_VOLVO}
                  sectionType={FormSelectSectionType.CHOOSE_MODEL}
                  currentNonVolvoVehicleFuelType={
                    currentNonVolvoVehicleFuelType
                  }
                  currentlySelectedCar={currentCar}
                  firstComparableVolvo={undefined}
                  secondComparableVolvo={undefined}
                  onValueChanged={(sectionType, parentCardType, changedValue) =>
                    handleFormSelectValueDidChange(
                      sectionType,
                      parentCardType,
                      changedValue
                    )
                  }
                />
              </div>
              <div className="col-span-full md:col-span-6 pb-5">
                <FormSelectSection
                  parentCardType={FormSelectParentCardType.SELECT_CURRENT_VOLVO}
                  sectionType={FormSelectSectionType.CHOOSE_FUEL_TYPE}
                  currentNonVolvoVehicleFuelType={
                    currentNonVolvoVehicleFuelType
                  }
                  currentlySelectedCar={currentCar}
                  firstComparableVolvo={undefined}
                  secondComparableVolvo={undefined}
                  onValueChanged={(sectionType, parentCardType, changedValue) =>
                    handleFormSelectValueDidChange(
                      sectionType,
                      parentCardType,
                      changedValue
                    )
                  }
                />
              </div>
            </div>
            <InfoBanner type={InfoBannerType.CantFindMyVolvo} />
          </div>
        ) : (
          <div className="col-span-full">
            <div className="pb-7">
              <InfoBanner type={InfoBannerType.DontOwnAVolvo} />
            </div>
            <FormSelectSection
              parentCardType={
                FormSelectParentCardType.SELECT_NON_VOLVO_FUEL_TYPE
              }
              sectionType={FormSelectSectionType.CHOOSE_FUEL_TYPE}
              currentNonVolvoVehicleFuelType={currentNonVolvoVehicleFuelType}
              currentlySelectedCar={currentCar}
              firstComparableVolvo={undefined}
              secondComparableVolvo={undefined}
              onValueChanged={(sectionType, parentCardType, changedValue) =>
                handleFormSelectValueDidChange(
                  sectionType,
                  parentCardType,
                  changedValue
                )
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CurrentVolvoSelectorSection;
