import React from "react";

/*-- components --*/
import InfoIcon from "./InfoIcon";

import { InfoBannerType } from "../../models/InfoBannerType";

interface InfoBannerProps {
  type: InfoBannerType;
  co2Emissions?: number;
}

const isComparativeVolvoCo2EmissionsInfoBanner = (
  type: InfoBannerType
): boolean => {
  return type === InfoBannerType.ComparativeVolvoCo2Emissions;
};

const getDynamicBottomLevelClassList = (type: InfoBannerType): string => {
  if (isComparativeVolvoCo2EmissionsInfoBanner(type)) {
    return "flex-col-reverse md:flex-row-reverse";
  } else {
    return "flex-col md:flex-row";
  }
};

const getInfoBannerTitle = (
  type: InfoBannerType,
  co2Emissions?: number
): string => {
  switch (type) {
    case InfoBannerType.DontOwnAVolvo:
      return "No problem";
    case InfoBannerType.CantFindMyVolvo:
      return "Can't find your car?";
    default:
      return `${
        co2Emissions !== undefined ? `${co2Emissions}g/km` : "Unspecified"
      }`;
  }
};

const getInfoBannerDescription = (type: InfoBannerType): string => {
  switch (type) {
    case InfoBannerType.DontOwnAVolvo:
      return "Since you don’t have a Volvo, just select a fuel type below, continue to the next section and manually enter the information.";
    case InfoBannerType.CantFindMyVolvo:
      return "No problem, just skip to the next section to manually enter your information.";
    case InfoBannerType.ComparativeVolvoCo2Emissions:
      return "This car has CO2 emissions of";
  }
};

const InfoBanner: React.FC<InfoBannerProps> = ({
  type,
  co2Emissions = undefined,
}) => {
  return (
    <div className="col-span-full bg-structure/100 p-5 flex flex-col md:flex-row items-center justify-center md:justify-start">
      <InfoIcon />
      <div>
        <div
          className={`flex ${getDynamicBottomLevelClassList(
            type
          )} items-center justify-center text-center md:text-left`}
        >
          <div className="text-lg font-volvo-novum--medium font-medium whitespace-nowrap pb-2 md:pb-0 md:pl-4">
            {getInfoBannerTitle(type, co2Emissions)}
          </div>
          <div className="text-lg font-volvo-novum--light font-light whitespace-normal md:pl-4">
            {getInfoBannerDescription(type)}
          </div>
        </div>
        {isComparativeVolvoCo2EmissionsInfoBanner(type) ? (
          <div className="text-sm font-volvo-novum--light font-light pl-4 text-center md:text-left">
            UK average CO2 emissions in 2018 were 124.5g/km
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default InfoBanner;
