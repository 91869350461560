import React from "react";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Tooltip from "./Tooltip";

import { CostCalculatorSliderSectionType } from "../models/CostCalculatorSliderSectionType";
import { FuelType } from "../models/FuelType";
import { Volvo } from "../models/Volvo";
import { CURRENT_PETROL_PRICE, CURRENT_DIESEL_PRICE } from "../App";

interface CostCalculatorSliderSectionProps {
  sectionType: CostCalculatorSliderSectionType;
  isCurrentCarAVolvo: boolean;
  currentVolvo: Volvo;
  currentNonVolvoVehicleFuelType: FuelType;
  value: number;
  onSliderValueChange: (updatedSliderValue: number) => void;
}

const getTitleText = (
  sectionType: CostCalculatorSliderSectionType,
  isCurrentCarAVolvo: boolean,
  currentVolvo: Volvo,
  currentNonVolvoVehicleFuelType: FuelType
): string => {
  switch (sectionType) {
    case CostCalculatorSliderSectionType.WEEKDAY_MILEAGE:
      return "My Daily mileage - weekday";
    case CostCalculatorSliderSectionType.WEEKEND_MILEAGE:
      return "My Daily mileage - weekend";
    case CostCalculatorSliderSectionType.FUEL_ECONOMY:
      return `My current ${getCorrectFuelTypeName(
        isCurrentCarAVolvo,
        currentVolvo,
        currentNonVolvoVehicleFuelType
      )} economy is:`;
    case CostCalculatorSliderSectionType.PETROL_COST:
      return `My current ${getCorrectFuelTypeName(
        isCurrentCarAVolvo,
        currentVolvo,
        currentNonVolvoVehicleFuelType
      )} cost per litre is:`;
    case CostCalculatorSliderSectionType.ELECTRICITY_COST:
      return "My current electricity economy is:";
  }
};

const needsTooltip = (
  sectionType: CostCalculatorSliderSectionType
): boolean => {
  switch (sectionType) {
    case CostCalculatorSliderSectionType.WEEKDAY_MILEAGE:
      return true;
    case CostCalculatorSliderSectionType.WEEKEND_MILEAGE:
      return true;
    case CostCalculatorSliderSectionType.FUEL_ECONOMY:
      return false;
    case CostCalculatorSliderSectionType.PETROL_COST:
      return false;
    case CostCalculatorSliderSectionType.ELECTRICITY_COST:
      return true;
  }
};

const getCorrectFuelTypeName = (
  isCurrentCarAVolvo: boolean,
  currentVolvo: Volvo,
  currentNonVolvoVehicleFuelType: FuelType
): string => {
  return isCurrentVehicleFuelTypePetrol(
    isCurrentCarAVolvo,
    currentVolvo,
    currentNonVolvoVehicleFuelType
  )
    ? "petrol"
    : "diesel";
};

const isCurrentVehicleFuelTypePetrol = (
  isCurrentCarAVolvo: boolean,
  currentVolvo: Volvo,
  currentNonVolvoVehicleFuelType: FuelType
): boolean => {
  return (
    (isCurrentCarAVolvo
      ? currentVolvo.fuelType
      : currentNonVolvoVehicleFuelType) === FuelType.PETROL
  );
};

const getSliderDefaultValue = (
  sectionType: CostCalculatorSliderSectionType,
  isCurrentCarAVolvo: boolean,
  currentVolvo: Volvo,
  currentNonVolvoVehicleFuelType: FuelType
): number => {
  switch (sectionType) {
    case CostCalculatorSliderSectionType.WEEKDAY_MILEAGE:
    case CostCalculatorSliderSectionType.WEEKEND_MILEAGE:
      return 20;
    case CostCalculatorSliderSectionType.FUEL_ECONOMY:
      return isCurrentCarAVolvo
        ? currentVolvo.mpg
        : currentNonVolvoVehicleFuelType === FuelType.PETROL
        ? 36.0
        : 42.0;
    case CostCalculatorSliderSectionType.PETROL_COST:
      return isCurrentVehicleFuelTypePetrol(
        isCurrentCarAVolvo,
        currentVolvo,
        currentNonVolvoVehicleFuelType
      )
        ? CURRENT_PETROL_PRICE
        : CURRENT_DIESEL_PRICE;
    case CostCalculatorSliderSectionType.ELECTRICITY_COST:
      return 0.14;
  }
};

const getSliderMinValue = (
  sectionType: CostCalculatorSliderSectionType
): number => {
  switch (sectionType) {
    case CostCalculatorSliderSectionType.WEEKDAY_MILEAGE:
    case CostCalculatorSliderSectionType.WEEKEND_MILEAGE:
      return 1;
    case CostCalculatorSliderSectionType.FUEL_ECONOMY:
      return 10;
    case CostCalculatorSliderSectionType.PETROL_COST:
      return 1.0;
    case CostCalculatorSliderSectionType.ELECTRICITY_COST:
      return 0.08;
  }
};

const getSliderMaxValue = (
  sectionType: CostCalculatorSliderSectionType
): number => {
  switch (sectionType) {
    case CostCalculatorSliderSectionType.WEEKDAY_MILEAGE:
    case CostCalculatorSliderSectionType.WEEKEND_MILEAGE:
    case CostCalculatorSliderSectionType.FUEL_ECONOMY:
      return 100;
    case CostCalculatorSliderSectionType.PETROL_COST:
      return 2.00;
    case CostCalculatorSliderSectionType.ELECTRICITY_COST:
      return 0.38;
  }
};

const isPetrolCostSliderSection = (
  sectionType: CostCalculatorSliderSectionType
): boolean => {
  return sectionType === CostCalculatorSliderSectionType.PETROL_COST;
};

const isElectricityCostSliderSection = (
  sectionType: CostCalculatorSliderSectionType
): boolean => {
  return sectionType === CostCalculatorSliderSectionType.ELECTRICITY_COST;
};

const getSliderStepValue = (
  sectionType: CostCalculatorSliderSectionType
): number => {
  switch (sectionType) {
    case CostCalculatorSliderSectionType.WEEKDAY_MILEAGE:
    case CostCalculatorSliderSectionType.WEEKEND_MILEAGE:
      return 1.0;
    case CostCalculatorSliderSectionType.FUEL_ECONOMY:
      return 0.1;
    case CostCalculatorSliderSectionType.PETROL_COST:
    case CostCalculatorSliderSectionType.ELECTRICITY_COST:
      return 0.01;
  }
};

const getCalculationPostfix = (
  sectionType: CostCalculatorSliderSectionType
): string => {
  switch (sectionType) {
    case CostCalculatorSliderSectionType.WEEKDAY_MILEAGE:
    case CostCalculatorSliderSectionType.WEEKEND_MILEAGE:
      return "miles";
    case CostCalculatorSliderSectionType.FUEL_ECONOMY:
      return "mpg";
    case CostCalculatorSliderSectionType.PETROL_COST:
      return "/litre";
    case CostCalculatorSliderSectionType.ELECTRICITY_COST:
      return "/kwh";
  }
};

const needsBottomInfoText = (
  sectionType: CostCalculatorSliderSectionType,
  isCurrentCarAVolvo: boolean
): boolean => {
  switch (sectionType) {
    case CostCalculatorSliderSectionType.PETROL_COST:
      return true;
    case CostCalculatorSliderSectionType.ELECTRICITY_COST:
      return true;
    case CostCalculatorSliderSectionType.FUEL_ECONOMY:
      return isCurrentCarAVolvo;
    default:
      return false;
  }
};

const getBottomInfoText = (
  sectionType: CostCalculatorSliderSectionType,
  isCurrentCarAVolvo: boolean,
  currentVolvo: Volvo,
  currentNonVolvoVehicleFuelType: FuelType
): string => {
  switch (sectionType) {
    case CostCalculatorSliderSectionType.FUEL_ECONOMY:
      return `The official Volvo mpg for your selected car is ${currentVolvo.mpg}mpg`;
    case CostCalculatorSliderSectionType.PETROL_COST:
      return `The national average ${getCorrectFuelTypeName(
        isCurrentCarAVolvo,
        currentVolvo,
        currentNonVolvoVehicleFuelType
      )} price is £${getSliderDefaultValue(
        sectionType,
        isCurrentCarAVolvo,
        currentVolvo,
        currentNonVolvoVehicleFuelType
      )}/litre`;
    case CostCalculatorSliderSectionType.ELECTRICITY_COST:
      return `The official average electricity price is £0.14/kwh`;
    default:
      return "";
  }
};

const CostCalculatorSliderSection: React.FC<CostCalculatorSliderSectionProps> = ({
  sectionType,
  isCurrentCarAVolvo,
  currentVolvo,
  currentNonVolvoVehicleFuelType,
  value,
  onSliderValueChange,
}) => {
  return (
    <div className="col-span-full md:col-span-1">
      <div className="flex items-center">
        <div className="text-lg font-volvo-novum--light font-light pb-3 pr-4">
          {getTitleText(
            sectionType,
            isCurrentCarAVolvo,
            currentVolvo,
            currentNonVolvoVehicleFuelType
          )}
        </div>
        {needsTooltip(sectionType) ? <Tooltip type={sectionType} /> : null}
      </div>
      <div className="flex flex-row items-end pb-2">
        {isPetrolCostSliderSection(sectionType) ||
        isElectricityCostSliderSection(sectionType) ? (
          <div className="text-2xl self-start font-volvo-novum--medium font-bold">
            £
          </div>
        ) : null}
        <div className="text-5xl leading-none font-volvo-novum--medium font-bold pr-1">
          {sectionType === CostCalculatorSliderSectionType.PETROL_COST
            ? value.toFixed(2)
            : value}
        </div>
        <div className="text-2xl font-volvo-novum--light font-light">
          {getCalculationPostfix(sectionType)}
        </div>
      </div>
      <Slider
        min={getSliderMinValue(sectionType)}
        max={getSliderMaxValue(sectionType)}
        defaultValue={getSliderDefaultValue(
          sectionType,
          isCurrentCarAVolvo,
          currentVolvo,
          currentNonVolvoVehicleFuelType
        )}
        onChange={(value) => onSliderValueChange(value)}
        step={getSliderStepValue(sectionType)}
        trackStyle={{
          backgroundColor: "#336BB4",
          height: 5,
        }}
        handleStyle={{
          borderColor: "#A4DAF7",
          height: 24,
          width: 24,
          marginLeft: 0,
          marginTop: -9,
          backgroundColor: "white",
        }}
        railStyle={{
          backgroundColor: "#E9E9E9",
          height: 5,
        }}
        value={value}
      />
      {needsBottomInfoText(sectionType, isCurrentCarAVolvo) ? (
        <div className="pt-4 font-volvo-novum--light font-light text-sm">
          {getBottomInfoText(
            sectionType,
            isCurrentCarAVolvo,
            currentVolvo,
            currentNonVolvoVehicleFuelType
          )}
        </div>
      ) : null}
    </div>
  );
};

export default CostCalculatorSliderSection;
