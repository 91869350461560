import React from "react";
import { FormSelectParentCardType } from "../models/FormSelectParentCardType";
import { FormSelectSectionType } from "../models/FormSelectSectionType";
import { Volvo } from "../models/Volvo";
import { volvos } from "../data/volvo-models";
import { FuelType } from "../models/FuelType";
import { VolvoModel } from "../models/VolvoModel";

interface FormSelectSectionProps {
  parentCardType: FormSelectParentCardType;
  sectionType: FormSelectSectionType;
  currentlySelectedCar?: Volvo;
  firstComparableVolvo?: Volvo;
  secondComparableVolvo?: Volvo;
  currentNonVolvoVehicleFuelType: FuelType;
  onValueChanged: (
    sectionType: FormSelectSectionType,
    parentCardType: FormSelectParentCardType,
    changedValue: VolvoModel | string | FuelType
  ) => void;
}

const getFormSelectSectionTitle = (type: FormSelectSectionType): string => {
  switch (type) {
    case FormSelectSectionType.CHOOSE_MODEL:
      return "What model?";
    case FormSelectSectionType.CHOOSE_CAR:
      return "Choose your car";
    case FormSelectSectionType.CHOOSE_FUEL_TYPE:
      return "Fuel type";
  }
};

const isFuelSelectorInPluginHybridParentCardType = (
  parentCardType: FormSelectParentCardType,
  type: FormSelectSectionType
): boolean => {
  return (
    parentCardType === FormSelectParentCardType.COMPARE_PLUGIN_HYBRID &&
    type === FormSelectSectionType.CHOOSE_FUEL_TYPE
  );
};

const getAvailableOptionsList = (
  parentCardType: FormSelectParentCardType,
  type: FormSelectSectionType,
  currentlySelectedCar?: Volvo,
  firstComparableVolvo?: Volvo,
  secondComparableVolvo?: Volvo
): string[] => {
  switch (type) {
    case FormSelectSectionType.CHOOSE_MODEL:
      const applicableVolvosByBodyType: Volvo[] = volvos.filter(
        (volvo) =>
          volvo.bodyType === currentlySelectedCar?.bodyType &&
          volvo.fuelType !== FuelType.PLUGIN_HYBRID
      );
      const modelsOfApplicableVolvos: VolvoModel[] =
        applicableVolvosByBodyType.map((volvo) => volvo.model);
      const modelsOfApplicableVolvosWithoutDuplicates: VolvoModel[] =
        modelsOfApplicableVolvos.filter(
          (model, index, self) => index === self.indexOf(model)
        );
      const modelsAsStrings: string[] =
        modelsOfApplicableVolvosWithoutDuplicates.map((model) =>
          getModelAsString(model)
        );
      return modelsAsStrings;

    case FormSelectSectionType.CHOOSE_CAR:
      switch (parentCardType) {
        case FormSelectParentCardType.SELECT_CURRENT_VOLVO:
          const applicableNonPluginHybridVolvosByModelAndStyle: Volvo[] =
            volvos.filter(
              (volvo) =>
                volvo.model === currentlySelectedCar?.model &&
                volvo.style === currentlySelectedCar?.style &&
                volvo.fuelType !== FuelType.PLUGIN_HYBRID
            );
          const specsOfApplicableNonPluginHybridVolvos: string[] =
            applicableNonPluginHybridVolvosByModelAndStyle.map(
              (volvo) => volvo.spec
            );
          const specsOfApplicableNonPluginHybridVolvosWithoutDuplicates: string[] =
            specsOfApplicableNonPluginHybridVolvos.filter(
              (spec, index, self) => index === self.indexOf(spec)
            );
          return specsOfApplicableNonPluginHybridVolvosWithoutDuplicates;

        case FormSelectParentCardType.SELECT_NON_VOLVO_FUEL_TYPE:
          /*-- we'll never logistically get here, so just pass back an empty array --*/
          return [];

        case FormSelectParentCardType.COMPARE_PLUGIN_HYBRID:
          const applicablePluginHybridVolvosByBodyType: Volvo[] = volvos.filter(
            (volvo) =>
              volvo.bodyType === firstComparableVolvo?.bodyType &&
              volvo.fuelType === FuelType.PLUGIN_HYBRID
          );
          const specsOfApplicablePluginHybridVolvos: string[] =
            applicablePluginHybridVolvosByBodyType.map((volvo) => volvo.spec);
          const specsOfApplicablePluginHybridVolvosWithoutDuplicates: string[] =
            specsOfApplicablePluginHybridVolvos.filter(
              (spec, index, self) => index === self.indexOf(spec)
            );
          return specsOfApplicablePluginHybridVolvosWithoutDuplicates;

        case FormSelectParentCardType.COMPARE_ANOTHER_VOLVO:
          const applicableVolvos: Volvo[] = volvos.filter(
            (volvo) =>
              volvo !== currentlySelectedCar &&
              volvo !== firstComparableVolvo &&
              volvo.bodyType === secondComparableVolvo?.bodyType &&
              volvo.fuelType === secondComparableVolvo?.fuelType
          );
          const specsOfApplicableVolvos: string[] = applicableVolvos.map(
            (volvo) => volvo.spec
          );
          const specsOfApplicableVolvosWithoutDuplicates: string[] =
            specsOfApplicableVolvos.filter(
              (spec, index, self) => index === self.indexOf(spec)
            );
          return specsOfApplicableVolvosWithoutDuplicates;

        /*-- 
          Put this in because of a problem with typescript switches with enums. 
          Enums are final, they are extensions on binary and all cases are handled 
          above so we will never ever hit this default. 
        --*/
        default:
          return [];
      }

    case FormSelectSectionType.CHOOSE_FUEL_TYPE:
      switch (parentCardType) {
        case FormSelectParentCardType.SELECT_CURRENT_VOLVO:
          const applicableNonPluginHybridVolvosByModelAndStyle: Volvo[] =
            volvos.filter(
              (volvo) =>
                volvo.bodyType === currentlySelectedCar?.bodyType &&
                volvo.model === currentlySelectedCar?.model &&
                volvo.fuelType !== FuelType.PLUGIN_HYBRID
            );
          const fuelTypesOfApplicableNonPluginHybridVolvos: FuelType[] =
            applicableNonPluginHybridVolvosByModelAndStyle.map(
              (volvo) => volvo.fuelType
            );
          const fuelTypesOfApplicableNonPluginHybridVolvosWithoutDuplicates: FuelType[] =
            fuelTypesOfApplicableNonPluginHybridVolvos.filter(
              (fuelType, index, self) => index === self.indexOf(fuelType)
            );
          return fuelTypesOfApplicableNonPluginHybridVolvosWithoutDuplicates.map(
            (fuelType) => getFuelTypeAsString(fuelType)
          );

        case FormSelectParentCardType.SELECT_NON_VOLVO_FUEL_TYPE:
          return [FuelType.PETROL, FuelType.DIESEL].map((fuelType) =>
            getFuelTypeAsString(fuelType)
          );

        case FormSelectParentCardType.COMPARE_PLUGIN_HYBRID:
          return [FuelType.PLUGIN_HYBRID].map((fuelType) =>
            getFuelTypeAsString(fuelType)
          );

        case FormSelectParentCardType.COMPARE_ANOTHER_VOLVO:
          /*-- COMPARE ANOTHER VEHICLE FUEL TYPE --*/
          /*-- what do we need here?:
                - vehicles whose spec do not match the currently selected vehicle
                - vehicles whose spec do not match the first comparable vehicle
                - vehicles whose bodyType match the second comparable vehicle
          --*/
          const applicableVolvos: Volvo[] = volvos.filter(
            (volvo) =>
              volvo !== currentlySelectedCar &&
              volvo !== firstComparableVolvo &&
              volvo.bodyType === secondComparableVolvo?.bodyType
          );

          const fuelTypesOfApplicableVolvos: FuelType[] = applicableVolvos.map(
            (volvo) => volvo.fuelType
          );
          const fuelTypesOfApplicableVolvosWithoutDuplicates: FuelType[] =
            fuelTypesOfApplicableVolvos.filter(
              (fuelType, index, self) => index === self.indexOf(fuelType)
            );
          return fuelTypesOfApplicableVolvosWithoutDuplicates.map((fuelType) =>
            getFuelTypeAsString(fuelType)
          );

        /*-- 
          Put this in because of a problem with typescript switches with enums. 
          Enums are final, they are extensions on binary and all cases are handled 
          above so we will never ever hit this default. 
        --*/
        default:
          return [];
      }

    /*-- 
      Put this in because of a problem with typescript switches with enums. 
      Enums are final, they are extensions on binary and all cases are handled 
      above so we will never ever hit this default. 
    --*/
    default:
      return [];
  }
};

const getCorrectVolvo = (
  parentCardType: FormSelectParentCardType,
  currentlySelectedCar?: Volvo,
  firstComparableVolvo?: Volvo,
  secondComparableVolvo?: Volvo
): Volvo | undefined => {
  switch (parentCardType) {
    case FormSelectParentCardType.SELECT_CURRENT_VOLVO:
      return currentlySelectedCar;
    case FormSelectParentCardType.COMPARE_PLUGIN_HYBRID:
      return firstComparableVolvo;
    case FormSelectParentCardType.COMPARE_ANOTHER_VOLVO:
      return secondComparableVolvo;
    default:
      return undefined;
  }
};

const getModelAsString = (model: VolvoModel | undefined): string => {
  switch (model) {
    case VolvoModel.XC40:
      return "XC40";
    case VolvoModel.XC60:
      return "XC60";
    case VolvoModel.XC90:
      return "XC90";
    case VolvoModel.S60:
      return "S60";
    case VolvoModel.V40:
      return "V40";
    case VolvoModel.V60:
      return "V60";
    case VolvoModel.V90:
      return "V90";
    default:
      return "Please select a model";
  }
};

const getModelFromString = (modelString?: String): VolvoModel => {
  switch (modelString) {
    case "XC40":
      return VolvoModel.XC40;
    case "XC60":
      return VolvoModel.XC60;
    case "XC90":
      return VolvoModel.XC90;
    case "S60":
      return VolvoModel.S60;
    case "S90":
      return VolvoModel.S90;
    case "V40":
      return VolvoModel.V40;
    case "V60":
      return VolvoModel.V60;
    case "V90":
      return VolvoModel.V90;
    default:
      return VolvoModel.UNDEFINED;
  }
};

const getFuelTypeAsString = (fuelType: FuelType | undefined): string => {
  switch (fuelType) {
    case FuelType.PETROL:
      return "Petrol";
    case FuelType.DIESEL:
      return "Diesel";
    case FuelType.PLUGIN_HYBRID:
      return "Plug-in hybrid";
    case FuelType.ELECTRIC:
      return "Electric";
    default:
      return "Please select a fuel type";
  }
};

const getFuelTypeFromString = (fuelTypeString?: string): FuelType => {
  switch (fuelTypeString) {
    case "Petrol":
      return FuelType.PETROL;
    case "Diesel":
      return FuelType.DIESEL;
    case "Plug-in hybrid":
      return FuelType.PLUGIN_HYBRID;
    case "Electric":
      return FuelType.ELECTRIC;
    default:
      return FuelType.UNDEFINED;
  }
};

const getSelectDefaultValue = (
  type: FormSelectSectionType,
  parentCardType: FormSelectParentCardType,
  currentNonVolvoVehicleFuelType: FuelType,
  firstComparableVolvo?: Volvo,
  currentlySelectedCar?: Volvo,
  secondComparableVolvo?: Volvo
): string => {
  const correctVolvo: Volvo | undefined = getCorrectVolvo(
    parentCardType,
    firstComparableVolvo,
    currentlySelectedCar,
    secondComparableVolvo
  );

  switch (type) {
    case FormSelectSectionType.CHOOSE_MODEL:
      return getModelAsString(correctVolvo?.model);
    case FormSelectSectionType.CHOOSE_CAR:
      return correctVolvo?.spec || "Please select a vehicle";
    case FormSelectSectionType.CHOOSE_FUEL_TYPE:
      return getFuelTypeAsString(
        parentCardType === FormSelectParentCardType.SELECT_NON_VOLVO_FUEL_TYPE
          ? currentNonVolvoVehicleFuelType
          : correctVolvo?.fuelType
      );
  }
};

const setValueChangedOption = (
  sectionType: FormSelectSectionType,
  valueChangedOptionString: string
): VolvoModel | string | FuelType => {
  switch (sectionType) {
    case FormSelectSectionType.CHOOSE_MODEL:
      return getModelFromString(valueChangedOptionString);
    case FormSelectSectionType.CHOOSE_FUEL_TYPE:
      return getFuelTypeFromString(valueChangedOptionString);
    case FormSelectSectionType.CHOOSE_CAR:
      return valueChangedOptionString;
  }
};

const FormSelectSection: React.FC<FormSelectSectionProps> = ({
  parentCardType,
  sectionType,
  currentNonVolvoVehicleFuelType,
  currentlySelectedCar,
  firstComparableVolvo,
  secondComparableVolvo,
  onValueChanged,
}) => {
  return (
    <label className="flex flex-col">
      <span className="text-lg font-volvo-novum--light font-light pb-2">
        {getFormSelectSectionTitle(sectionType)}
      </span>
      {isFuelSelectorInPluginHybridParentCardType(
        parentCardType,
        sectionType
      ) ? (
        <div className="mt-1 border border-structure/300 font-volvo-novum py-2 px-3 text-body/800">
          Plug-in Hybrid
        </div>
      ) : (
        <select
          className="block w-full mt-1 font-volvo-novum border-structure/300"
          defaultValue={getSelectDefaultValue(
            sectionType,
            parentCardType,
            currentNonVolvoVehicleFuelType,
            firstComparableVolvo,
            currentlySelectedCar,
            secondComparableVolvo
          )}
          onChange={(e) =>
            onValueChanged(
              sectionType,
              parentCardType,
              setValueChangedOption(sectionType, e.target.value)
            )
          }
        >
          {getAvailableOptionsList(
            parentCardType,
            sectionType,
            currentlySelectedCar,
            firstComparableVolvo,
            secondComparableVolvo
          ).map((optionName, index) => (
            <option key={index} value={optionName}>
              {optionName}
            </option>
          ))}
        </select>
      )}
    </label>
  );
};

export default FormSelectSection;
