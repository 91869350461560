import React from "react";
import Switch from "react-switch";

interface VolvoCarSwitchSectionProps {
  isToggledOn: boolean;
  handleToggleDidChange: () => void;
}

const VolvoCarSwitchSection: React.FC<VolvoCarSwitchSectionProps> = ({
  isToggledOn,
  handleToggleDidChange,
}) => {
  return (
    <div className="col-span-full lg:col-end-7 pb-3 flex flex-col md:flex-row">
      <div className="text-3xl font-volvo-novum--light font-light leading-none md:pr-7">
        Your current vehicle
      </div>
      <label className="flex items-center pt-3 md:pt-0">
        <Switch
          onChange={handleToggleDidChange}
          checked={isToggledOn}
          width={72}
          height={36}
          handleDiameter={28}
          onColor={"#336BB4"}
          offColor={"#BDBDBD"}
          uncheckedIcon={false}
        />
        <span className="text-base font-volvo-novum--light font-light pl-2">
          I currently have a Volvo
        </span>
      </label>
    </div>
  );
};

export default VolvoCarSwitchSection;
