import React from "react";
import CostSummaryBarItem from "./CostSummaryBarItem";
import { CostSummaryBarItemType } from "../models/CostSummaryBarItem";

interface CostSummaryBarProps {
  price: number;
  needsLeftPadding: boolean;
}

const CostSummaryBar: React.FC<CostSummaryBarProps> = ({
  price,
  needsLeftPadding = false,
}) => {
  return (
    <div
      className={`col-span-full w-full flex flex-row overflow-x-auto ${
        needsLeftPadding ? "pl-4" : ""
      }`}
    >
      <CostSummaryBarItem
        type={CostSummaryBarItemType.ITEM_3_YEARS}
        price={price}
      />
      <CostSummaryBarItem
        type={CostSummaryBarItemType.ITEM_2_YEARS}
        price={price}
      />
      <CostSummaryBarItem
        type={CostSummaryBarItemType.ITEM_1_YEAR}
        price={price}
      />
      <CostSummaryBarItem
        type={CostSummaryBarItemType.ITEM_1_MONTH}
        price={price}
      />
    </div>
  );
};

export default CostSummaryBar;
